import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const PageLoader = () => {
    return (
        <div className='loading'>
            <FontAwesomeIcon icon={faSpinner} className='icon' />
        </div>
    )
}

export default PageLoader