import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNodes } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { PageLoader } from './Loaders'
import { toast } from 'react-toastify'

const RegisterForm = () => {
    const { urlId } = useParams()
    const [linkData, setLinkData] = useState()
    const [userData, setUserData] = useState()
    const [update, setUpdate] = useState(true)

    const getLinkData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'admin_panel',
                    table: 'registerLinks',
                    type: 'urlId',
                    specific: urlId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setLinkData(response.data)
            if (response.data.length > 0) {
                setUserData({
                    name: '',
                    email: '',
                    password: '',
                    passwordCheck: '',
                    rights: response.data[0].rights
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getLinkData()
    }, [])

    const setText = (text, type) => {
        switch (type) {
            case 'name':
                userData.name = text
                break
            case 'email':
                userData.email = text
                break
            case 'password':
                userData.password = text
                break
            case 'passwordCheck':
                userData.passwordCheck = text
                break
        }
        setUpdate(!update)
    }

    const registerUser = async (e) => {
        e.preventDefault()
        if (userData.password !== userData.passwordCheck) {
            return toast.error('Hesla se neshodují')
        }
        delete userData.passwordCheck
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/site/register', {
                data: JSON.stringify(userData)
            },
            {
                params: {
                    database: 'admin_panel',
                    table: 'users'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            if (!response.data.registered) {
                return toast.error('Nastala chyba při registraci, možná jsou už tyto údaje použity')
            }
            toast.success('Registrace proběhla úspěšně')
            setUserData({name: "", email: "", password: "", passwordCheck: "", rights: linkData[0].rights})
            setUpdate(!update)
        } catch (error) {
            console.log(error);
        }
    }

    if (!linkData) {
        return <PageLoader />
    } else if (!linkData?.length) {
        return (
            <div className="content">
                <h1>Tento link není platný</h1>
            </div>
        )
    }

    return (
        <>
            {
                linkData[0].enable ?
                <div className='login'>
                    <div className="content">
                        <h2 className='headline'><FontAwesomeIcon icon={faCircleNodes} className='icon' />Registrace</h2>
                        <p className='description'>Vítejte, tato stránka slouží k registraci účtu do administrace své stránky</p>
                        <form onSubmit={(e) => {registerUser(e)}} className="form">
                            <div className="form-group">
                                <p>Jméno a Přijmení</p>
                                <input type="text" value={userData.name} onChange={(e) => {setText(e.target.value, 'name')}} required placeholder='Jméno a Přijmení' />
                            </div>
                            <div className="form-group">
                                <p>Email</p>
                                <input type="email" value={userData.email} onChange={(e) => {setText(e.target.value, 'email')}} autoComplete='off' required placeholder='Email' />
                            </div>
                            <div className="form-group">
                                <p>Heslo</p>
                                <input type="password" value={userData.password} onChange={(e) => {setText(e.target.value, 'password')}} autoComplete='off' required placeholder='Heslo' />
                            </div>
                            <div className="form-group">
                                <p>Heslo znovu</p>
                                <input type="password" value={userData.passwordCheck} onChange={(e) => {setText(e.target.value, 'passwordCheck')}} required placeholder='Heslo znovu' />
                            </div>
                            <button className='btn btn-primary'>Registrovat</button>
                        </form>
                    </div>
                </div>:
                <div className="login">
                    <div className="content">
                        <h2 className='headline'><FontAwesomeIcon icon={faCircleNodes} className='icon' />Tento link již není platný</h2>
                        <p className="description">Tento link již není aktivní, buď zažádej o nový nebo o obnovení platnosti linku.</p>
                    </div>
                </div>
            }
        </>
    )
}

export default RegisterForm