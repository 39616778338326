import { Main } from './components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <Main />
      <ToastContainer autoClose={3000} limit={5} />
    </>
  )
}

export default App;
