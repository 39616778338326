import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ErrorPage from './Errors/ErrorPage'
import RegisterForm from './RegisterForm'

const Main = () => {
    return (
        <Routes>
            <Route path='/'>
                <Route path=':urlId' element={<RegisterForm />} />
            </Route>
            <Route path='/*' element={<ErrorPage />} />
        </Routes>
    )
}

export default Main